<template>
  <div class="select-outer">
    <!-- <van-nav-bar
      safe-area-inset-top
      title="券品"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    /> -->
    <div class="filter-top top2" v-if="selectType != '5'">
      <!-- @search="onSearch" -->
      <div class="search-block">
        <van-search
          v-model="valueSearch"
          placeholder="请输入券名称"
          background="#FFF"
          shape="round"
          show-action
          :clearable="clearableNo"
        >
          <template #action>
            <div @click="onSearch" class="search-act">搜索</div>
          </template>
        </van-search>
      </div>
      <div class="top-select-drop">
        <van-dropdown-menu active-color="#006c50">
          <van-dropdown-item
            v-model="dropdownValue"
            :options="dropdownList"
            @change="dropdownChange"
          />
        </van-dropdown-menu>
      </div>
    </div>
    <div class="filter-top top2" v-if="selectType == '5'">
      <template v-if="selectType == '5'">
        <!-- <div class="top-select">
            <el-select v-model="dropStatusValue">
              <el-option
                v-for="item in dropStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
        <div class="top-select">
          <el-select v-model="dropTicketNumValue">
            <el-option
              v-for="item in dropTicketNumOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-block">
          <!-- @search="onSearchDelay" -->
          <van-search
            v-model="valueSearch"
            :placeholder="dropTicketNumValue == '3' ? '套号' : '券号'"
            background="#FFF"
            shape="round"
            class="search"
            show-action
            :clearable="clearableNo"
          >
            <template #action>
              <div @click="onSearchDelay" class="search-act">搜索</div>
            </template>
          </van-search>
        </div>
        <span
          class="height-search"
          @click="showHeightSearch"
          v-if="selectType == '5'"
          >高级搜索</span
        >
      </template>
    </div>
    <!-- 操作的下标{{ optionCarIndex }}```申请类型{{ selectType }}````````{{
      btnCur
    }}` -->

    <div class="con-outer">
      <div class="filter-top subfilter">
        <div class="type-btns" v-if="selectType == '2' || selectType == '3'">
          <span
            :class="{ 'btn-comm': true, 'cur-btn': btnCur == 1 }"
            @click="changeBtb(1)"
            >纸质</span
          >
          <span
            :class="{ 'btn-comm': true, 'cur-btn': btnCur == 2 }"
            @click="changeBtb(2)"
            >电子</span
          >
        </div>
      </div>
      <!-- 券延期item带多选，单独拎出来selectType是5是延期 -->
      <template v-if="selectType != '5'">
        <template v-for="(item, index) in rightList">
          <div class="con-item" :key="index">
            <div class="item-title" @click="goDetailtem(item)">
              {{ item.brandName }}
            </div>
            <!-- 调拨申请 -->
            <template v-if="selectType == '1'">
              <div class="item-line-between">
                <span class="left" v-if="item.validTimeType == '1'">
                  有效期：以套明细为准
                </span>
                <span class="left" v-else>
                  有效期：{{ item.timeEnd
                  }}{{ item.timeType == "0" ? "天" : "" }}
                </span>
                <div class="right">
                  <div class="attrtiButus">
                    <!-- {{ isSavaPaper(item, "1") }} -->
                    <div
                      :class="{
                        item: true,
                        'item-hui': !isSavaPaper(item, '1'),
                      }"
                      @click="addPaperOrElc(item, 1)"
                      v-if="[...item.attributeType].includes('1')"
                    >
                      <img
                        v-if="!isSavaPaper(item, '1')"
                        src="../../assets/image/hdui.png"
                        class="item-s-img"
                      />
                      <img
                        v-else
                        src="../../assets/image/lvhui.png"
                        class="item-s-img"
                      />
                      <span>纸质</span>
                    </div>
                    <!-- {{ isSavaPaper(item, "2") }} -->
                    <div
                      :class="{
                        item: true,
                        'item-hui': !isSavaPaper(item, '2'),
                      }"
                      @click="addPaperOrElc(item, 2)"
                      v-if="[...item.attributeType].includes('2')"
                    >
                      <img
                        v-if="!isSavaPaper(item, '2')"
                        src="../../assets/image/hdui.png"
                        class="item-s-img"
                      />
                      <img
                        v-else
                        src="../../assets/image/lvhui.png"
                        class="item-s-img"
                      />
                      <span>电子</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <!-- 销售礼券、免费礼券、折扣申请 -->
            <template
              v-else-if="
                selectType == '2' || selectType == '3' || selectType == '4'
              "
            >
              <div class="item-line-clum">
                <span class="one" v-if="item.validTimeType == '1'">
                  有效期：以套明细为准
                </span>
                <span class="one" v-else
                  >有效期：{{ item.timeEnd
                  }}{{ item.timeType == "0" ? "天" : "" }}
                </span>
                <span class="two" v-if="selectType != '3'"
                  >面额：¥{{ item.brandPrice }}</span
                >
                <!-- isSavaPaper -->
                <div class="three" @click="addPaperOrElc(item, -1)">
                  <div class="circle" v-if="isSavaPaper(item, -1)">
                    <van-icon name="add" color="#ccc" size="1.8rem" />
                  </div>
                  <div class="circle" v-else>
                    <van-icon name="add" color="#006c50" size="1.8rem" />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </template>
      <template v-else>
        <!-- 券延期 -->
        <!-- <el-checkbox-group v-model="checkedList" @change="handleCheckedChange"> -->
        <template v-for="(item, index) in rightList">
          <div class="delay-item-outer" :key="index">
            <div class="con-item-delay" @click="goDetailtem(item)">
              <span class="status-img"> {{ item.status | typeFilter }} </span>

              <div class="item-title">
                {{ item.brandName }}
              </div>
              <!-- <div class="item-title-code">
                {{ item.goodsBn }}
              </div> -->
              <div class="item-line-con btween">
                <div class="t-and-txt">
                  <span class="color-t">券号：</span>
                  <span>{{ item.goodsBn }}</span>
                </div>
              </div>
              <div class="item-line-con btween">
                <div class="t-and-txt flex-max">
                  <span class="color-t">券介质：</span>
                  <span
                    >{{ item.attributeType == "1" ? "纸质" : "电子" }}券</span
                  >
                </div>
                <div class="t-and-txt">
                  <span class="color-t">券用途：</span>
                  <span>{{
                    item.useType == "1"
                      ? "销售"
                      : item.useType == "2"
                      ? "免费"
                      : "工具"
                  }}</span>
                </div>
              </div>

              <div class="item-line-con btween">
                <!-- <span class="one" v-if="item.validTimeType == '1'">
                  有效期：以套明细为准
                </span> -->
                <div
                  class="t-and-txt flex-max"
                  v-if="item.validTimeType == '1'"
                >
                  <span class="color-t">有效期：以套明细为准</span>
                </div>
                <div class="t-and-txt flex-max" v-else>
                  <span class="color-t">有效期：</span>
                  <span>{{ item.endTime }}</span>
                </div>
                <div class="t-and-txt">
                  <span class="color-t">是否过期：</span>
                  <span>{{ item.isEffective == "1" ? "是" : "否" }}</span>
                </div>
              </div>

              <div class="item-line-con btween">
                <div class="t-and-txt">
                  <span class="color-t">券类：</span>
                  <span>{{ item.categoryName }}</span>
                </div>
              </div>
            </div>
            <div class="checkout-outer" @click="changeCheckItem(item, index)">
              <van-icon
                name="checked"
                color="#006c50"
                size="2rem"
                v-if="item.checkFlag == '1'"
              />
              <van-icon name="circle" color="#006c50" size="2rem" v-else />
            </div>
            <!-- <el-checkbox :label="item.id" :key="item.id" ></el-checkbox> -->
          </div>
        </template>
        <!-- </el-checkbox-group> -->
      </template>
      <!-- <van-tree-select
        height="100%"
        :items="items"
        :main-active-index.sync="active"
      >
        <template #content>
         
        </template>
      </van-tree-select> -->
    </div>

    <div class="submit-btn-outer">
      <div class="totalNum">
        <div class="submit-nums" v-if="selectType == '2' || selectType == '3'">
          <span class="username-num"
            >{{
              carList[this.optionCarIndex].userName
                ? carList[this.optionCarIndex].userName
                : "当前用户"
            }}已选：</span
          >
          <span class="num">{{
            carList[this.optionCarIndex].carListbyUser.length
          }}</span>
          <span>种</span>
        </div>
        <div class="submit-nums" v-if="selectType != '2' && selectType != '3'">
          <span>共计：</span>
          <span class="num">{{ selectTickCount }}</span>
          <span>种</span>
        </div>
      </div>

      <!-- 延期的要展示延期按钮，其他叫已选好了 -->
      <template>
        <van-button round type="info" color="#006C50" @click="submitSelect"
          >选好了</van-button
        >
      </template>
      <!-- <template>
        <van-button round type="info" color="#006C50" @click="submitDelay"
          >延期</van-button
        >
      </template> -->
    </div>

    <van-popup
      v-model="showSku"
      closeable
      round
      position="bottom"
      :style="{ 'max-height': '90%' }"
    >
      <div class="popup-sku-con-outer">
        <div class="pop-title-con">
          <span>券状态</span>
        </div>
        <div class="pop-check-outer">
          <template v-for="(item, index) in dropStatusOptions">
            <span
              :class="{
                'check-item': true,
                activity: item.value == dropStatusValue,
              }"
              :key="index"
              @click="changeCheck(item.value)"
              >{{ item.label }}</span
            >
          </template>
        </div>
        <div class="pop-title-con" @click="changeShowAllType">
          <span>券类</span>
          <van-icon
            name="arrow-down"
            size="1.4rem"
            color="#333"
            v-if="!showAllType"
          />
          <van-icon name="arrow-up" size="1.4rem" color="#333" v-else />
        </div>
        <div class="pop-check-outer">
          <!-- text: item.categoryName,
              value: item.id, -->
          <template v-for="(item, index) in popTickTypelist">
            <span
              :class="{
                'check-item': true,
                activity: item.value == popActiveTicketType,
              }"
              :key="index"
              @click="changeTickCheck(item.value)"
              >{{ item.text }}</span
            >
          </template>
        </div>
        <div class="pop-title-con">
          <span>券品</span>
        </div>
        <div class="pop-check-outer">
          <template v-for="(item, index) in dropTicketNumOptions">
            <span
              :class="{
                'check-item': true,
                activity: item.value == dropTicketNumValue,
              }"
              :key="index"
              @click="changeCheck1(item.value)"
              >{{ item.label }}</span
            >
          </template>
        </div>
        <!-- bianma -->
        <div class="input-outer">
          <span class="input-pop-title">开始编码：</span>
          <div class="edit-input">
            <el-input
              v-model="popStartNum"
              placeholder="请填写"
              clearable
              type="number"
            ></el-input>
          </div>
        </div>
        <div class="input-outer">
          <span class="input-pop-title">结束编码：</span>
          <div class="edit-input">
            <el-input
              v-model="popEndNum"
              placeholder="请填写"
              clearable
              type="number"
            ></el-input>
          </div>
        </div>
        <div class="pop-title-con">
          <span>券介质</span>
        </div>
        <div class="pop-check-outer">
          <template v-for="(item, index) in popDielectriclist">
            <span
              :class="{
                'check-item': true,
                activity: item.value == popActiveDielectric,
              }"
              :key="index"
              @click="changeDlCheck(item.value)"
              >{{ item.text }}</span
            >
          </template>
        </div>
        <div class="pop-title-con">
          <span>券用途</span>
        </div>
        <div class="pop-check-outer">
          <template v-for="(item, index) in popUselist">
            <span
              :class="{
                'check-item': true,
                activity: item.value == popActiveUse,
              }"
              :key="index"
              @click="changeUseCheck(item.value)"
              >{{ item.text }}</span
            >
          </template>
        </div>
        <div class="pop-title-con">
          <span>时间类型</span>
        </div>
        <div class="pop-check-outer">
          <template v-for="(item, index) in popTimeTypelist">
            <span
              :class="{
                'check-item': true,
                activity: item.value == popActiveTimeType,
              }"
              :key="index"
              @click="changeTimeTypeCheck(item.value)"
              >{{ item.text }}</span
            >
          </template>
        </div>

        <!-- <van-datetime-picker
  v-model="currentDate"
  type="date"
  title="选择年月日"
  :min-date="minDate"
  :max-date="maxDate"
/> -->
        <div class="pop-date-line-outer">
          <span class="title">开始与结束时间：</span>
          <div class="line-right" @click="popRightChange">
            <span>{{ rightStartTime }}</span>
            <span
              >{{ rightStartTime ? "更改" : "请选择"
              }}<i class="el-icon-arrow-down"></i
            ></span>
          </div>
        </div>
        <div class="pop-btns">
          <van-button round plain @click="resetPop">重置</van-button>
          <van-button type="primary" round @click="popBottomBtnSearch"
            >搜索</van-button
          >
        </div>
      </div>
    </van-popup>
    <!-- 延期 -->
    <van-popup
      v-model="showDelayPop"
      closeable
      round
      position="bottom"
      :style="{ 'max-height': '90%' }"
    >
      <div class="popup-sku-con-outer delay-outer">
        <div class="pop-title-con">
          <span>延期</span>
        </div>
        <div class="input-outer">
          <span class="input-pop-title">延期天数：</span>
          <div class="edit-input">
            <el-input
              v-model="popDelayDays"
              placeholder="请填写"
              clearable
              type="number"
            ></el-input>
            <span>天</span>
          </div>
        </div>

        <div class="pop-date-line-outer">
          <span class="title">延期至：</span>
          <div class="line-right" @click="popDelayChange">
            <span>{{ delayToTime }}</span>
            <span
              >{{ delayToTime ? "更改" : "请选择"
              }}<i class="el-icon-arrow-down"></i
            ></span>
          </div>
        </div>
        <div class="pop-btns">
          <!-- <van-button round plain>重置</van-button> -->
          <div class="count">
            <span>合计：</span>
            <span class="num">32</span>
            <span>张</span>
          </div>
          <van-button type="primary" round @click="popBottomBtnSearch1"
            >确认</van-button
          >
        </div>
      </div>
    </van-popup>

    <van-calendar
      v-model="showPopStartDate"
      @confirm="btnPopStartDate"
      :min-date="minDate"
      :max-date="maxDate"
      type="range"
      color="#006C50"
    />
    <van-calendar
      v-model="showPopDelay"
      @confirm="btnPopDelayDate"
      color="#006C50"
    />
    <el-drawer
      size="100%"
      direction="rtl"
      title="券详情"
      style="z-index: 1"
      :visible.sync="tickDetailVisible"
    >
      <tickRequestDetails1
        :itemCurrentItemStr="itemCurrentItemStr"
        ref="detailRef"
      ></tickRequestDetails1>
    </el-drawer>
    <!-- <van-calendar
      v-model="showPopDelay"
      @confirm="btnPopDelayDate"
      :max-date="maxDate"
      :min-date="minDate"
      color="#006C50"
    /> -->
    <!-- <van-number-keyboard safe-area-inset-bottom /> -->
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapGetters } from "vuex";
import tickRequestDetails1 from "./tickRequestDetails1.vue";
import {
  getAllCategory,
  getBrandByCategoryId,
  getDelayBnList,
} from "@/api/process";
import moment from "moment";
export default {
  components: {
    tickRequestDetails1,
  },
  data() {
    return {
      valueSearch: "",
      tickDetailVisible: false,
      itemCurrentItemStr: "",
      // btnCur: this.carList[this.optionCarIndex].carListbyUser.length>0?this.carList[this.optionCarIndex].carListbyUser[0].attributeType:1,
      btnCur: 1,
      active: 0,
      that: this,
      clearableNo: false,
      showAllType: false,
      showSku: false,
      showPopDelay: false,
      popDelayDays: "",
      showDelayPop: false,
      popDelayDays: "",
      dropdownValue: "",
      result: [],
      optionCarIndex: "",
      dropStatusValue: "1",
      dropTicketNumValue: "2",
      checkedListItem: [],
      delayToTime: "",
      maxDate: moment().toDate(),
      minDate: new Date(0),
      //minDate: moment().subtract(30, "days").toDate(),
      showPopStartDate: false,
      inputPrice: 0,
      popStartNum: "",
      popEndNum: "",
      rightStartTime: "",
      popActiveTypeCrrent: 1,
      dropStatusOptions: [
        {
          value: "1",
          label: "已过期",
        },
        {
          value: "0",
          label: "未过期",
        },
      ],
      dropTicketNumOptions: [
        {
          value: "2",
          label: "券号",
        },
        {
          value: "3",
          label: "套号",
        },
      ],
      // poptypelist: [
      //   { text: "已过期", value: 1 },
      //   { text: "未过期", value: 2 },
      // ],
      popActiveTimeType: 1,
      //时间类型：1入库时间，2调拨时间 3发放/销售时间 4核销时间 5生效时间
      popTimeTypelist: [
        { text: "入库日期", value: 1 },
        { text: "调拨日期", value: 2 },
        { text: "发放/销售日期", value: 3 },
        { text: "核销日期", value: 4 },
        { text: "生效日期", value: 5 },
      ],
      popActiveUse: "",
      //：1：销售 2:免费 3：工具
      popUselist: [
        { text: "全部", value: "" },
        { text: "销售", value: 1 },
        { text: "免费", value: 2 },
        { text: "工具", value: 3 },
      ],
      popActiveDielectric: "",
      popDielectriclist: [
        { text: "全部", value: "" },
        { text: "纸质券", value: 1 },
        { text: "电子券", value: 2 },
      ],
      popActiveTicketType: "",
      popTickTypelist: [],

      searchStartTime: "",
      searchEndTime: "",
      valueNum: 1,
      selectType: "", //1.调拨单申请2.销售礼券申请 3免费礼券申请4.销售折扣申请5券品延期申请
      rightList: [
        // {
        //   id: "01",
        //   title: "中秋月饼",
        //   num: 0,
        //   price: 0,
        // },
        // {
        //   id: "02",
        //   title: "usi对双对数",
        //   num: 0,
        //   price: 0,
        // },
        // {
        //   id: "03",
        //   title: "水电费是的",
        //   num: 0,
        //   price: 0,
        // },
        // {
        //   id: "04",
        //   title: "二二",
        //   num: 0,
        //   price: 0,
        // },
        // {
        //   id: "05",
        //   title: "人人",
        //   num: 0,
        //   price: 0,
        // },
        // {
        //   id: "06",
        //   title: "中润融通融让他让他秋月饼",
        //   num: 0,
        //   price: 0,
        // },
        // {
        //   id: "07",
        //   title: "中秋月啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊的饼",
        //   num: 0,
        //   price: 0,
        // },
        // {
        //   id: "08",
        //   title: "中秋啥的月饼",
        //   num: 0,
        //   price: 0,
        // },
        // {
        //   id: "09",
        //   title: "啥的二",
        //   num: 0,
        //   price: 0,
        // },
        // {
        //   id: "10",
        //   title: "哈哈哈",
        //   num: 0,
        //   price: 0,
        // },
        // {
        //   id: "11",
        //   title: "发发发",
        //   num: 0,
        //   price: 0,
        // },
      ],
      dropdownList: [],
      goods: {
        // 默认商品 sku 缩略图
        picture: "https://img01.yzcdn.cn/1.jpg",
      },
    };
  },
  filters: {
    //// 1:在库，2:调拨，3:销售，4:发放，5:转赠，6:核销,7:强制核销，8:确认收入，9:作废
    typeFilter(mType) {
      if (mType == "1") {
        return "在库";
      } else if (mType == "2") {
        return "调拨";
      } else if (mType == "3") {
        return "销售";
      } else if (mType == "4") {
        return "发放";
      } else if (mType == "5") {
        return "转赠";
      } else if (mType == "6") {
        return "核销";
      } else if (mType == "7") {
        return "强制核销";
      } else if (mType == "8") {
        return "确认收入";
      } else if (mType == "9") {
        return "作废";
      } else {
        return "";
      }
    },
  },
  methods: {
    isSavaPaper(item, v) {
      let jz = v == "-1" ? this.btnCur : v;
      //console.log("````" + JSON.stringify(item));
      let tepCarList = [...this.$store.state.sendTickCarList];
      //let tepCarList = [...this.carList];
      //纸质
      // let jz = "1";
      let saveInCarIndex = tepCarList[
        this.optionCarIndex
      ].carListbyUser.findIndex(
        (itemCar) =>
          itemCar.brandId == item.id &&
          itemCar.brandName == item.brandName &&
          jz == itemCar.attributeType
      );
      if (saveInCarIndex > -1) {
        return true;
      } else {
        return false;
      }
    },
    goDetailtem(itemString) {
      //tickRequestDetails
      //  let itemParam=JSON.parse(item)
      // let ParamObj={
      //    ...itemParam,
      //     groupIndex:groupIndex,
      //     subIndex:subIndex,
      //     carSelectType:this.selectType
      // }
      let detailObj = {
        ...itemString,
        modelNo: this.selectType,
        isDfaultData: 1, //使用接口查询默认的详情
      };

      this.itemCurrentItemStr = JSON.stringify(detailObj);
      // this.$refs.detailRef.opening(this.itemCurrentItemStr);
      this.tickDetailVisible = true;
      // this.$router.push({
      //   path: "/tickRequestDetails",
      //   query: {
      //     paramObj: itemString,
      //     isEdit: 0,
      //     selectType: this.selectType,
      //   },
      // });
    },
    changeShowAllType() {
      this.showAllType = !this.showAllType;
      this.getAllTypeDelayList();
    },
    //搜索重置
    resetPop() {
      this.dropStatusValue = "1"; //券状态
      this.popActiveTicketType = ""; //券分类
      this.dropTicketNumValue = "2"; //券品 券or套号
      this.popStartNum = ""; //开始编码
      this.popEndNum = ""; //结束编码
      this.popActiveDielectric = ""; //券介质
      this.popActiveUse = ""; //券用途
      this.popActiveTimeType = 1; //时间类型
      this.searchStartTime = "";
      this.searchEndTime = "";
      this.rightStartTime = "";
      this.showSku = false;
      this.getDelayList();
    },
    popBottomBtnSearch() {
      this.showSku = false;
      this.getDelayList();
    },
    addPaperOrElc(item, v) {
      //item当前item.v介质1纸质2电子
      //optionCarIndex 操作的购物车下标
      let tepCarList = [...this.$store.state.sendTickCarList];
      //先判断购物车里有没有你点的数据，没有的话才能加入
      let jz = v == "-1" ? this.btnCur : v;
      let saveInCarIndex = tepCarList[
        this.optionCarIndex
      ].carListbyUser.findIndex(
        (itemCar) =>
          itemCar.brandId == item.id &&
          itemCar.brandName == item.brandName &&
          jz == itemCar.attributeType
      );
      // console.log("购物车里找到了" + JSON.stringify(saveInCarIndex));
      if (saveInCarIndex > -1) {
        tepCarList[this.optionCarIndex].carListbyUser.splice(saveInCarIndex, 1);
        //Toast.fail("券品已存在，请勿重复添加");
      } else {
        tepCarList[this.optionCarIndex].carListbyUser.push({
          ...item,
          //  attributeType: v == "-1" ? this.btnCur : v, //纸质、电子
          attributeType: jz, //纸质、电子
          brandId: item.id,
          userName: tepCarList[this.optionCarIndex].userName,
          userPhone: tepCarList[this.optionCarIndex].userPhone,
          shenqingCount: 1,

          sellPrice: item.brandPrice, //销售申请的单价
          discountedPrice: item.brandPrice, //折扣申请的单价
          discount: 100,
          discountedTotalPrice: item.brandPrice,
          days: 0, //延期天数
          optTime: item.endTime, //延期至的年月日
        });
      }
      //console.log("开始添加");

      this.$store.commit("setTickCarList", tepCarList);
    },
    dropdownChange(v) {
      // console.log("下拉选了" + v);
      this.dropdownValue = v;
      this.getBrandByCategoryList();
    },
    getAllCategoryList() {
      getAllCategory()
        .then((rsp) => {
          this.dropdownList = rsp.data.data.map((item) => {
            return {
              text: item.categoryName,
              value: item.id,
            };
          });
          //btnCur: this.carList[this.optionCarIndex].carListbyUser.length>0?this.carList[this.optionCarIndex].carListbyUser[0].attributeType:1,
          this.dropdownValue = this.dropdownList[0].value;
          if (
            this.selectType == "2" ||
            this.selectType == "3" ||
            this.selectType == "4"
          ) {
            //不管哪个用户选过介质在这就不可以切换介质了
            //先看所有用户里有没有已选项目，有的话直接把介质赋值给介质按钮
            let indexFind = this.carList.findIndex(
              (item) => item.carListbyUser && item.carListbyUser.length > 0
            );
            //哪个用户都是空，那就是默认的1，可以切换，

            if (indexFind == "-1") {
              this.btnCur = 1;
              console.log(
                "没有已选介质···" + indexFind + "介质this.btnCur=" + this.btnCur
              );
            } else {
              this.btnCur =
                this.carList[indexFind].carListbyUser[0].attributeType;
              console.log(
                "有已选介质···" + indexFind + "介质this.btnCur=" + this.btnCur
              );
            }
            //   this.btnCur=this.carList[this.optionCarIndex].carListbyUser.length>0?this.carList[this.optionCarIndex].carListbyUser[0].attributeType:1
          } else {
            this.btnCur = 1;
          }
          this.getBrandByCategoryList();

          // Toast.success("提交成功");
          // this.submitLoading = false;
          // this.$router.push("/workspace/submit");
        })
        .catch((err) => {
          Toast.fail("获取券类失败");
        });
    },
    //可以延期的券类列表
    //   this.dropStatusValue="1"//券状态
    //   this.popActiveTicketType="" //券分类
    //   this.dropTicketNumValue="2"//券品 券or套号
    //   this.popStartNum=""//开始编码
    //   this.popEndNum=""//结束编码
    //   this.popActiveDielectric=""//券介质
    //   this.popActiveUse=""//券用途
    //   this.popActiveTimeType=1//时间类型
    //      this.searchStartTime = ""
    //  this.searchEndTime =""
    //  this.rightStartTime =""
    getAllTypeDelayList() {
      getAllCategory()
        .then((rsp) => {
          this.popTickTypelist = rsp.data.data.map((item) => {
            return {
              text: item.categoryName,
              value: item.id,
            };
          });
          this.popTickTypelist.unshift({
            text: "全部",
            value: "",
          });
          //控制一下展示全部还是展示部分
          if (!this.showAllType) {
            this.popTickTypelist = this.popTickTypelist.slice(0, 10);
          }
        })
        .catch((err) => {
          Toast.fail("获取延期券类失败");
        });
    },
    getBrandByCategoryList() {
      //console.log("获取券列表"+this.dropdownValue+"``"+this.valueSearch)
      //v-if="selectType == '2'||selectType == '3'||selectType == '4'"
      //有切换电子纸质的时候才传她，没有的时候传空
      // let attrtBute=""
      // if(this.selectType=='2'||this.selectType=='3'||this.selectType=='4'){
      //   attrtBute=this.btnCur
      // }else{
      //   attrtBute=""
      // }
      //attrtBute纸质还是电子的入参
      this.rightList = [];
      let temParams = {};
      if (this.selectType == "1") {
        //调拨单申请type：2
        temParams = {
          type: 2,
        };
      } else if (this.selectType == "2" || this.selectType == "4") {
        //销售礼券申请+销售折扣申请useType：1
        temParams = {
          useType: 1,
        };
      } else if (this.selectType == "3") {
        temParams = {
          useType: 2,
        };
      }
      getBrandByCategoryId({
        categoryId: this.dropdownValue,
        brandName: this.valueSearch,
        ...temParams,
      })
        .then((rsp) => {
          //v-if="selectType == '2'||selectType == '3'||selectType == '4'"
          if (
            this.selectType == "2" ||
            this.selectType == "3" ||
            this.selectType == "4"
          ) {
            //自己筛选一下数据根据电子还是纸质的

            this.rightList = rsp.data.data.filter((item) => {
              if (item.attributeType.includes(this.btnCur)) {
                //  console.log(item.attributeType+"```"+)
                return true;
              } else {
                return false;
              }
            });
            //   console.log("自己赛选之后" + JSON.stringify(this.rightList));
          } else {
            this.rightList = rsp.data.data;
          }
          this.rightList = this.rightList.map((item) => {
            return {
              ...item,
              isUpdateLimit: 0, //是否编辑使用限制保存过了
            };
          });
        })
        .catch((err) => {
          Toast.fail("获取券类失败");
        });
    },
    //延期的券查询
    getDelayList() {
      this.rightList = [];
      getDelayBnList({
        goodsBn: this.valueSearch, ///搜搜索框
        isValid: this.dropStatusValue, //是否有效 0:无效 1：有效

        categoryId: this.popActiveTicketType,
        brandType: this.dropTicketNumValue, //（1：空白券，2：单体券，3：套券）

        startGoodsBn: this.popStartNum,

        endGoodsBn: this.popEndNum,

        attributeType: this.popActiveDielectric, //券介质 1:纸质，2：电子

        useType: this.popActiveUse, //用途属性：1：销售 2:免费 3：工具

        timeType: this.popActiveTimeType, //时间类型：1入库时间，2调拨时间 3发放/销售时间 4核销时间 5生效时间

        searchStartTime: this.searchStartTime,
        searchEndTime: this.searchEndTime,
      })
        .then((rsp) => {
          // 获取原购物车列表数据

          // 根据id查找到就返回索引，查找不到返回 -1
          //const index = newList.findIndex((v) => v.id == detail.name);
          // this.rightList = rsp.data.data
          const newList = [...this.$store.state.sendTickCarList];
          this.rightList = rsp.data.data.map((item) => {
            let indexFind = newList[
              this.optionCarIndex
            ].carListbyUser.findIndex((v) => v.id == item.id);
            // console.log("纸质电子的"+item.attributeType)
            console.log("aaaa" + indexFind);

            if (indexFind < 0) {
              return {
                ...item,
                isUpdateLimit: 0, //是否编辑使用限制保存过了
                checkFlag: "0",
              };
            } else {
              //有
              return {
                ...item,
                isUpdateLimit: 0, //是否编辑使用限制保存过了
                checkFlag: "1",
              };
            }
          });
        })
        .catch((err) => {
          Toast.fail("获取可延期券列表失败");
        });
    },
    //改变购物车

    changeCheckItem(item, index) {
      let newList = [...this.$store.state.sendTickCarList];
      let indexFind = newList[this.optionCarIndex].carListbyUser.findIndex(
        (v) => v.id == item.id
      );
      if (indexFind > -1) {
        //现在有，想从购物车删除
        //  找到在购物车的下标

        //删除
        newList[this.optionCarIndex].carListbyUser.splice(indexFind, 1);
      } else {
        //现在是没有想添加购物车
        newList[this.optionCarIndex].carListbyUser.push({
          ...item,
          checkFlag: "1",
          userName: newList[this.optionCarIndex].userName,
          userPhone: newList[this.optionCarIndex].userPhone,
          shenqingCount: 1,
          sellPrice: item.brandPrice, //销售申请的单价
          discountedPrice: item.brandPrice, //折扣申请的单价
          bnId: item.id,
          discount: 100,
          discountedTotalPrice: item.brandPrice,
          days: 0, //延期天数
          optTime: item.endTime, //延期至的年月日
        });
      }
      this.$store.commit("setTickCarList", newList);
      ///列表也要更新一下
      let rightIndex = this.rightList.findIndex((v) => v.id == item.id);
      // if(item.checkFlag=='1'){}
      if (rightIndex > -1) {
        this.rightList[rightIndex].checkFlag =
          item.checkFlag == "1" ? "0" : "1";
      }
    },
    showHeightSearch() {
      this.showSku = true;
    },
    popDelayChange() {
      this.showPopDelay = true;
    },
    submitDelay() {
      this.showDelayPop = true;
    },
    handleCheckedChange(selectArr) {
      console.log(
        "一个条目多选了" +
          JSON.stringify(selectArr) +
          "```" +
          this.checkedListItem
      );
    },

    popBottomBtnSearch1() {},
    popRightChange() {
      this.showPopStartDate = true;
    },
    formatDate(date) {
      return `${date.getYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    // onConfirm(date) {
    //   const [start, end] = date;
    //   this.show = false;
    //   this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    // },
    btnPopStartDate(date) {
      const [start, end] = date;

      this.showPopStartDate = false;
      this.searchStartTime = moment(start).format("YYYY-MM-DD");
      this.searchEndTime = moment(end).format("YYYY-MM-DD");
      this.rightStartTime = this.searchStartTime + "至" + this.searchEndTime;

      // this.rightStartTime = `${this.formatDate(start)} - ${this.formatDate(
      //   end
      // )}`;
    },
    btnPopDelayDate(date) {
      //  this.delayToTime=date
      this.showPopDelay = false;
      this.delayToTime = moment(date).format("YYYY-MM-DD");
    },
    changeTimeTypeCheck(v) {
      this.popActiveTimeType = v;
    },
    changeUseCheck(v) {
      this.popActiveUse = v;
    },
    changeDlCheck(v) {
      this.popActiveDielectric = v;
    },
    changeCheck(v) {
      this.dropStatusValue = v;
    },
    changeCheck1(v) {
      this.dropTicketNumValue = v;
    },

    changeTickCheck(v) {
      this.popActiveTicketType = v;
    },

    // 检验
    validator(v) {
      return /^(\d+)(\.\d{1,2})?$/.test(v.toString()); //只能输入两个小数
    },
    inputChange(item) {
      console.log("输入了" + JSON.stringify(item));
      this.changeCar(item);
      //看看在不在购物车；里。在的话需要修一下购物车里的item价格
    },
    //一些操作需要更改购物车里信息的
    changeCar(item) {
      const newList = [...this.$store.state.sendTickCarList];
      const index = newList.findIndex((v) => v.id == item.id);
      if (index >= 0) {
        newList[index] = { ...item };
        // 更新 Vuex 数据
        this.$store.commit("setTickCarList", newList);
      }
    },
    submitSelect() {
      this.$router.back();
    },
    goEditTick(id) {
      this.$router.push({
        path: "/tickRequest",
        query: { id: id },
      });
    },
    changeBtb(cur) {
      //看看购物车里有没有数据，所有用户都算，只要有。就不让切换了
      let indexFind = this.carList.findIndex(
        (item) => item.carListbyUser.length > 0
      );
      console.log(
        "购物车里有数据吗？" + indexFind + "this.btnCur```" + this.btnCur
      );
      if (indexFind != "-1") {
        Toast.fail("去上一级页面清空已选条目之后才能切换介质");
        return;
      } else {
        this.btnCur = cur;
        this.getBrandByCategoryList();
      }
    },
    onSearch(value) {
      this.getBrandByCategoryList();
    },
    onSearchDelay() {
      this.getDelayList();
    },
    onClickLeft() {
      this.$router.back();
    },
  },
  computed: {
    ...mapGetters(["selectTickCount"]),
    carList() {
      return [...this.$store.state.sendTickCarList];
    },
  },
  created() {
    console.log("选券created");
  },
  destroyed() {
    console.log("选券destroyed");
  },
  mounted() {
    this.selectType = this.$route.query.modeNo;
    this.optionCarIndex = this.$route.query.optionCarIndex;
    if (this.selectType == "5") {
      //延期调用的事单独的接口
      this.getDelayList();
      this.getAllTypeDelayList();
    } else {
      //其他类型是相同的获取列表接口
      this.getAllCategoryList();
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     console.log("````beforeRouteEnter"from.path);
  //     if (from.path == "/workspace/forms") {
  //       // console.log("工作台进来的" + from.path);
  //       if (vm.$isNotEmpty(vm.$route.query.code)) {
  //         vm.getUserDept();
  //         vm.loadFormInfo(vm.$route.query.code);
  //         //console.log(this.$route.query.modeNo+"每个item带的类型")
  //         vm.selectType = vm.$route.query.modeNo;

  //         //  this.carList = [...this.$store.state.sendTickCarList];
  //         //1.调拨单申请2.销售礼券申请 ，3免费礼券申请,4销售折扣申请,5礼券延期申请,空串表示非我们定义的模板
  //       } else {
  //         Toast.fail("参数缺失");
  //       }
  //     }
  //   });

  // },
};
</script>
<style lang="less" >
.el-scrollbar {
  .el-scrollbar__bar {
    opacity: 1 !important;
  }
}

.el-select .el-input__inner {
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
</style>
<style lang="less" scoped>
.select-outer {
  height: 100vh;
  width: 100vw;
  position: relative;
  // background-color: pink !important;
  /deep/ .van-dropdown-menu__title {
    color: #006c50 !important;
  }
  .search-act {
    background-color: #006c50;
    color: #fff;
    padding: 0 1rem;
    border-radius: 0.8rem;
    font-size: 1.2rem;
  }
  .popup-sku-con-outer {
    padding: 1rem;

    .pop-title-con {
      margin: 1rem 0;
      font-size: #333;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .delay-outer {
    padding: 2rem;
    .pop-btns {
      margin-top: 10rem;
      display: flex;
      justify-content: space-between;
      font-size: 1.5rem;
      /deep/ .van-button {
        height: 4rem;
        line-height: 4rem;
      }
      .count {
        display: flex;
        align-items: center;
      }
      .num {
        font-size: 2.4rem;
        color: #006c50;
        padding: 0 1rem;
      }
    }
    .pop-btns-edit-user {
      display: flex;
      justify-content: center;
      /deep/ .van-button {
        height: 4rem;
        line-height: 4rem;
        width: 80vw;
      }
    }
  }
  .delay-item-outer {
    display: flex;
    margin-bottom: 1.5rem;
    align-items: center;
    /deep/ .el-checkbox__label {
      display: none;
    }
    /deep/ .el-checkbox__inner {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-left: 1rem;
    }
  }
  .pop-date-line-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    .title {
      font-size: #333;
      font-size: 1.4rem;
    }
    .line-right {
      flex: 1;
      justify-content: space-between;
      margin-left: 1.5rem;
      display: flex;
      align-items: center;
      span {
        font-size: 1.4rem;
        height: 1.4rem;
        line-height: 1.4rem;
      }
    }
  }
  .input-outer {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    .input-pop-title {
      font-size: #333;
      font-size: 1.3rem;
    }
    .edit-input {
      flex: 1;
      display: flex;
      align-items: center;
      /deep/ .el-input {
        font-size: 1.3rem;
        margin-left: 0.5rem;
        width: 90%;
        border: none;
        border-bottom: 1px solid #ccc;
      }
      /deep/ .el-input__inner {
        height: 3rem;
        border: none;
      }
      /deep/ .el-input__suffix {
        height: 3rem;
        line-height: 3rem;
        display: flex;
        align-items: center;
      }
      /deep/ .el-textarea {
        font-size: 1.3rem;
      }
    }
  }
  .pop-check-outer {
    display: flex;
    flex-wrap: wrap;

    .check-item {
      color: #b6b6b6;
      margin-bottom: 1.5rem;
      border: 0.1rem solid #b6b6b6;
      padding: 0.5rem 1rem;
      margin-right: 1rem;
      border-radius: 0.6rem;
      font-size: 1.3rem;
    }
    .activity {
      color: #006c50;
      border: 0.1rem solid #eef5f3;
      background-color: #eef5f3;
    }
  }
  .pop-btns {
    display: flex;
    justify-content: space-around;
    margin: 2rem 0 1rem;
    /deep/ .van-button--default {
      color: #006c50;
      width: 40%;
      background-color: #fff;
      border: 1px solid #006c50;
      font-size: 1.5rem;
    }
    /deep/ .van-button--primary {
      color: #fff;
      width: 40%;
      background-color: #006c50;
      border: 1px solid #006c50;
      font-size: 1.5rem;
    }
  }
  background-color: #f8f8f8;
  /deep/ .van-search {
    padding: 0;

    background-color: #f8f8f8;
    .van-icon {
      font-size: 1.5rem;
    }
  }
  /deep/ .van-field__control {
    font-size: 1.3rem;
  }
  /deep/ .van-search__content {
    background-color: #fff;
    border-radius: 1rem;
  }

  /deep/ .van-sidebar-item--select::before {
    background-color: #006c50;
  }
  /deep/ .van-nav-bar .van-icon {
    color: #333;
  }
  /deep/ .van-sidebar-item__text {
    font-size: 1.3rem;
  }
  /deep/ .van-nav-bar__text {
    color: #333;
  }
  .filter-top {
    /deep/.van-icon {
      font-weight: 600;
      color: #006c50;
    }
    /deep/ .el-input__inner {
      border: none !important;
      width: 7rem;
      padding: 0.3rem;
      height: 3rem !important;
      // background-color: #eef5f3;
      line-height: 3rem;
      font-size: 1.2rem;
      color: #006c50;
    }
    /deep/ .el-input__icon {
      width: 1.3rem;
    }
    /deep/ .el-input__icon {
      height: 3rem !important;
      line-height: 3rem;
      color: #006c50;
    }
    .top-selec {
      /deep/ .el-input {
        border: none !important;
      }
    }
    .top-select-drop {
      margin-left: 3rem;
      margin-right: 1rem;
      display: flex;

      align-items: center;
      /deep/ .van-dropdown-menu__bar {
        background-color: #f8f8f8 !important;
        box-shadow: none;
        height: 3.5rem;
      }
      /deep/ .van-dropdown-menu__title {
        font-size: 1.3rem;
      }
    }
    // margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    .search-block {
      flex: 1;
      /deep/ .van-field__control {
        font-size: 1.2rem;
      }
    }
    .height-search {
      height: 3rem;
      line-height: 3rem;
      font-size: 1.2rem;
      color: #006c50;
      margin-left: 1rem;
    }
    .type-btns {
      width: 13rem;
      height: 3rem;
      line-height: 3rem;
      opacity: 1;
      border-radius: 1.5rem;
      background: #ffffff;
      box-shadow: 0px 2px 10px #ccc;
      color: #333333;
      font-size: 1.3rem;
      .btn-comm {
        display: inline-block;
        width: 50%;
        text-align: center;
        color: #333333;
      }
      .cur-btn {
        color: #fff;
        background-color: #006c50;
        border-radius: 1.5rem;
      }
    }
    .top-select {
      margin-right: 1rem;
    }
    .more-search {
      color: #006c50;
    }
  }
  .top2 {
    width: 100vw;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0 !important;
    padding-top: 0 !important;
    max-height: 5rem !important;
    position: absolute;
    top: 0;
    z-index: 100;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .subfilter {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .con-outer {
    padding: 0 1rem;
    flex: 1;
    height: 100vh;
    padding-top: 6rem;
    padding-bottom: 7rem;
    // height: 68vh;

    overflow-y: scroll;
    margin-bottom: 5rem;
    margin-top: 1rem;
    .con-item {
      position: relative;
      padding: 2rem 0.8rem 1rem;
      border-radius: 1rem;
      background-color: #fff;
      margin-bottom: 1.5rem;
      .type {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0.6rem;
        color: #006c50;
        height: 2rem;
        line-height: 2rem;
        font-size: 1.3rem;
        padding: 0 0.8rem;
        background: #eef5f3;
      }
      .item-title {
        color: #333;
        font-size: 1.4rem;
        word-wrap: break-word;
        white-space: normal;
        padding-bottom: 0.7rem;
        font-weight: 600;
      }
      .item-title-code {
        color: #999;
      }
      .item-line {
        display: flex;
        justify-content: space-between;
        font-size: 1.3rem;
        color: #666666;
        align-items: center;
      }
      .item-line-con {
        font-size: 1.3rem;
        color: #666666;
        margin-bottom: 0.7rem;
      }
      .item-line-between {
        font-size: 1.3rem;
        color: #666666;
        margin-bottom: 0.7rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .attrtiButus {
        display: flex;
        align-items: center;
        .item {
          font-size: 1.2rem;
          padding: 0.8rem 2rem;
          margin-right: 1rem;
          border-radius: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #333;
          background-color: #f0f4f3;
          color: #006c50;
          display: flex;
          align-items: center;
          flex-direction: row;
          position: relative;
        }
        .item-hui {
          color: #8a8a8a;
          background-color: #f7f7f7;
        }
        .item-s-img {
          height: 1rem;
          position: absolute;
          top: 0;
          right: 0;
        }
        .circle {
          margin-top: 0.1rem;
          margin-right: 0.5rem;
        }
      }
      .item-line-clum {
        font-size: 1.3rem;
        color: #666666;
        margin-bottom: 0.7rem;
        display: flex;
        align-items: center;
        .one {
          flex: 3;
        }
        .two {
          flex: 3;

          padding-left: 1rem;
        }
        .three {
          flex: 1;
          display: flex;
          justify-content: right;

          .circle {
            text-align: center;
            padding: 0.3rem 1rem;
            font-weight: 600;
          }
        }
      }
      .t-and-txt {
        display: flex;
        align-items: center;
        .color-t {
          color: #999;
        }
      }

      .btween {
        display: flex;
        justify-content: space-between;
      }
      .item-line-option {
        font-size: 1.3rem;
        color: #666666;
        margin-bottom: 0.7rem;
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/ .el-input {
          font-size: 1.3rem;

          width: 12rem;
          border: none;
          border-bottom: 0.2rem solid #ccc;
        }
        /deep/ .el-input__inner {
          height: 3rem;
          border: none;
        }
        /deep/ .el-input__suffix {
          height: 3rem;
          line-height: 3rem;
          display: flex;
          align-items: center;
        }
        // /deep/ .el-input__suffix{
        //   height: 2rem;
        //    border: none;
        //    line-height: 2rem;

        // }
        /deep/ .van-field {
          padding: 0;
          width: 10rem;
          font-size: 1.3rem;
        }
        .price {
          width: 6rem;
        }
      }
    }
    .checkout-outer {
      padding-left: 1rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
    .con-item-delay {
      flex: 1;
      padding: 1rem;
      border-radius: 1rem;
      overflow: hidden;
      align-items: center;
      position: relative;
      box-shadow: 0px 2px 14px rgba(0, 49, 36, 0.2);

      .status-img {
        color: #333;
        font-size: 1.4rem;
        word-wrap: break-word;
        white-space: normal;
        padding: 0.5rem 1rem 0.5rem 1.5rem;
        background-color: #ffeaea;
        text-align: center;
        color: #f45753;
        font-size: 1.1rem;
        position: absolute;
        border-bottom-left-radius: 50%;
        top: 0;
        right: 0;
      }
      .item-title {
        color: #333;
        font-size: 1.4rem;
        word-wrap: break-word;
        white-space: normal;
        padding-bottom: 0.7rem;
        max-width: 60vw;

        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .item-title-code {
        color: #666;
        color: #666;
        font-size: 1.4rem;
      }
      .item-line-con {
        font-size: 1.3rem;
        color: #666666;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.7rem;
      }
      .t-and-txt {
        flex: 1;
        display: flex;
        align-items: center;
        .color-t {
          color: #999;
        }
      }
      .flex-max {
        flex: 1.5;
      }
    }
  }
  .submit-btn-outer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    height: 7rem;
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: space-between;
    padding: 0 1.5rem;
    padding-bottom: 0.5rem;
    .submit-nums {
      font-size: 1.5rem;
    }
    .num {
      font-weight: 600;
      padding: 0 0.8rem;
      color: #006c50;
    }
    /deep/ .van-button {
      font-size: 1.5rem;
      padding: 0.1rem 2rem;
      height: 3rem;
      line-height: 3rem;
    }
  }
}
</style>
